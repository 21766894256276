<template>
  <div class="page">
    <header-bar />
    <div class="title">
      <img :src="partnerImg"/>
      <div class="title-desc">
        <span>Partner</span>
      </div>
    </div>
    <div class="partner-info">
      <div class="info-item" v-for="(item, index) of partnerList" :key="index">
        <img :src="item.icon">
        <span class="item-desc">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import partnerImg from '../assets/partner.png';

export default {
  name: "PartnerPage",
  data() {
    return {
      partnerImg,
      partnerList: [
        {
          icon: require("@/assets/patner/1.png"),
          name: "Ghana Police"
        },
        {
          icon: require("@/assets/patner/2.png"),
          name: "Bank Of Ghana"
        },
        {
          icon: require("@/assets/patner/3.png"),
          name: "GCB"
        },
        {
          icon: require("@/assets/patner/4.png"),
          name: "CalBank"
        },{
          icon: require("@/assets/patner/5.png"),
          name: "UBA"
        },
        {
          icon: require("@/assets/patner/6.png"),
          name: "adb"
        },
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.page {
  background-color: @base-color;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    padding: 10% 0 5%;
    display: flex;
    flex-direction: row;
    img {
      width: 45%;
    }
    .title-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
      }
    }
  }

  .partner-info {
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    padding: 0 20px;

    .info-item {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #F4F4F4;
      padding: 3% 5%;
      border-radius: 8px;
      img {
        width: 15%;
        margin-right: 10%;
      }
      .item-desc {
        margin: 10px 0;
      }
    }
  }
}
</style>
