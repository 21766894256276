<template>
  <div class="page">
    <div class="welcome-div">{{ $t('welcome') }}</div>
    <div class="top">
      <div class="apply-tips">
        <span class="top-tips">{{ $t('ssd_12') }}</span>
        <span class="amount">{{ $t('currency') }}<span>{{ `${minAmount}-${maxAmount}` }}</span></span>
        <div class="tips-item-box">
          <span class="tips-item">
            {{ $t('ssd_18') }}: {{ minRate }}%
          </span>
          <span class="tips-item">
            {{ $t('ssd_19') }}: {{ maxPeriod }} {{ $t('month') }}
          </span>
        </div>
      </div>
    </div>
<!--    <div class="top2"></div>-->
<!--    <div class="top3"></div>-->
    <div class="apply-box">
      <van-button @click.native="checkUser" class="default-background" block>
        {{ $t(btnDesc) }}
      </van-button>
      <van-checkbox class="policy-div" v-model="checked" icon-size="16px">
        <span class="read">{{$t('read_and_agree')}}
          <a class="policy" @click="showPolicy = true" @click.stop="(event)=>event.stopPropagation()">
            ({{$t('policy_financial_department')}})</a>
        </span>
      </van-checkbox>
    </div>
    <div class="icon-div">
      <template v-for="(item, index) of iconList">
        <div class="icon-content" @click="item.click" v-if="item.show" :key="index" :class="item.class">
          <div class="left-div">
            <img class="icon" :src="item.icon"/>
            <span class="icon-title">{{ item.title }}</span>
          </div>
<!--          <i class="el-icon-arrow-right"></i>-->
        </div>
      </template>
    </div>
    <bottom-bar current-name="Home"></bottom-bar>
    <signature-dialog :next="goNext" :visible="showSign"/>
    <div class="policy-content-box" v-if="showPolicy" @click="showPolicy=false">
      <div class="policy-content" @click.stop="(event)=>event.stopPropagation()">
        <p>The borrower must fill in personal information in the system, and all information must be filled in
          truthfully.</p>
        <p> • If the borrower provides untrue information, the company can pursue legal responsibility against the
          borrower.</p>
        <p> • All information confirmed by the borrower cannot be changed. Whether the approval result is passed or not
          comes from the credit review committee and cannot be changed.</p>
        <p> • The borrower must comply with the outcome of the board's final resolution in some important event.</p>
        <p> • The borrower must comply with the deliberation results of the board of directors within the specified
          period.
          After the inspection is completed, the total amount will be credited to the account filled in by the borrower
          in the system. However, if the borrower does not fulfill the terms of the contract approved by Finance, the
          contract will be cancelled.
          If the contract is terminated, the borrower must pay a processing fee of 50% of the loan amount in order to
          remove all balances from the system.
        <p> • If there is any error after submitting personal information and the error is caused by the borrower,
          the borrower shall be responsible for this event Incorrect information will be corrected in accordance with
          company policy.
          If the borrower does not bear the mistakes caused by himself, the company can confiscate the borrower's
          securities according to law.
          This would be considered serious intentional fraud and could face severe legal penalties if brought directly
          to court in accordance with Article 1170 of the Civil Code,
          also known as breach of Contract.</p>
      </div>
    </div>
    <!--    <div class="bottom-img">-->
    <!--      <img :src="bottomImg">-->
    <!--    </div>-->
    <van-overlay :show="showReject" class-name="reject-box">
      <div class="wrapper" @click.stop>
        <span class="error-msg">Since there is no contact information for you
The system has declined, please contact customer service to reactivate</span>
        <van-button type="info" block @click="store.contactUs">
          {{ $t('click_to_contact_service') }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import interestRateImg from '@/assets/interest_rate.png';
import calendarImg from '@/assets/calendar.png';
import getLoanImg from '@/assets/get-loan.png';
import loanRecordImg from '@/assets/loan-record.png';
import helpImg from '@/assets/help.png';
import transactionHistoryImg from '@/assets/transaction-history.png';
import websiteImg from '@/assets/website.png';
import cooperationImg from '@/assets/cooperation.png';
import rateImg from '@/assets/rate.png';
import bottomImg from '@/assets/bottom.png';
import walletImg from '@/assets/wallet.png';
import store from '@/store';
import {appService, orderService} from "@/services";

export default {
  name: "HomePage",
  data() {
    return {
      interestRateImg,
      calendarImg,
      getLoanImg,
      loanRecordImg,
      helpImg,
      transactionHistoryImg,
      websiteImg,
      cooperationImg,
      rateImg,
      bottomImg,
      walletImg,
      store,
      checked: true,
      statusSource: null,
      info: null,
      appName: process.env.DEPLOY_CONFIGS.title,
      unAuth: false,
      loading: false,
      condition: false,
      showSign: false,
      loanProgress: null,
      btnDesc: 'ssd_1',
      showPolicy: false,
      website: null,
      showReject: false,
      iconList: [
        {
          icon: loanRecordImg,
          click: () => this.goPage('/repayDetail'),
          title: this.$t('repay_title'),
          show: true
        },
        {
          icon: helpImg,
          click: store.contactUs,
          title: this.$t('help'),
          show: store.showContact
        },
        {
          icon: transactionHistoryImg,
          click: () => this.goPage('/transactionHistory'),
          title: this.$t('transaction_history'),
          show: true,
        },
        {
          icon: websiteImg,
          click: () => this.showPolicy = true,
          title: this.$t('mine_about_us'),
          show: true
        },
        {
          icon: cooperationImg,
          click: () => this.goPage('/partner'),
          title: this.$t('cooperation'),
          show: true
        },
        {
          icon: rateImg,
          click: () => this.goPage('/contract'),
          title: this.$t('contract'),
          show: true
        },
        {
          icon: walletImg,
          click: this.checkUser,
          title: this.$t('wallet'),
          show: true
        },
      ],
      minAmount: 80000,
      maxAmount: 3000000,
      maxPeriod: 24,
      minRate: 0.4,
      amountList: [],
      rateList: [],
      periodDays: [],
    }
  },
  async activated() {
    await this.getAmountRange();
    this.info = await store.getUser(true);
    this.statusSource = this.info.userQualificationView;
    if ((this.statusSource.bankCard != 1 && this.statusSource.bankCard != 2)
        || this.statusSource.basicInformation != 1 && this.statusSource.basicInformation != 2
        || (this.statusSource.identity != 1 && this.statusSource.identity != 2)) {
      this.unAuth = true;
    } else {
      this.unAuth = false;
    }
    this.loanProgress = await orderService.getLoanProgress();
    this.showReject = this.loanProgress.status === 5
    if (this.unAuth) {
      this.btnDesc = 'ssd_1';
    } else if (!this.loanProgress || !this.loanProgress.status) {
      this.btnDesc = 'common_apply';
    } else {
      this.btnDesc = 'wallet';
    }
    await this.getConfig();
    this.showSign = false;
  },
  methods: {
    async getConfig() {
      this.website = await store.getConfig("website")
    },
    async getAmountRange() {
      let periodConfigs = await appService.getPeriodConfig({appId: process.env.DEPLOY_CONFIGS.appId});
      periodConfigs.rateConfigs.forEach(r => {
        this.amountList = this.amountList.concat(r.amounts)
        this.periodDays.push(r.periodDays)
        this.rateList.push(r.rate / 1000)
      });
      this.periodDays.sort((a, b) => a - b)
      this.amountList.sort((a, b) => a - b)
      this.rateList.sort((a, b) => a - b)
      this.minRate = this.rateList[0];
      this.maxPeriod = this.periodDays[this.periodDays.length-1];
      this.minAmount = this.amountList[0];
      this.maxAmount = this.amountList[this.amountList.length-1];
    },
    checkUser() {
      if (!this.info.signatureImg) {
        this.showSign = true;
      } else {
        this.goNext();
      }
    },
    goNext() {
      if (this.unAuth) {
        this.$router.push('/baseInfo');
      } else if (!this.loanProgress || !this.loanProgress.status) {
        store.getAppList().then((appList) => {
          this.$router.push("/apply/" + appList[0].id);
        });
      } else {
        this.$router.push("/detail/" + this.loanProgress.orderId);
      }
    },
    goPage(path) {
      this.$router.push(path);
    },
    goWebsite() {
      window.open(this.website, "_blank");
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  padding-bottom: 57px;
  min-height: calc(100% - 57px);
  background: @base-color;

  .welcome-div {
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    padding: 20px;
  }

  .top {
    padding: 20px;


    .top-tips {
      display: block;
      color: #FFFFFF;
      font-size: 16px;
      padding-top: 10px;
      font-weight: 400;
    }

    .apply-tips {
      background: linear-gradient(180deg, #e7b227 0%, #3236e4 100%);
      color: #FFFFFF;
      text-align: left;
      border-radius: 10px;
      padding: 50px 20px 20px;

      .amount {
        margin-top: 10px;
        display: block;
        font-size: 28px;
        font-weight: 700;
      }

      .split-line {
        margin: 15px 25px 15px;
        height: 1px;
        border-bottom: 1px dashed #FFFFFF47
      }

      .apply-title {
        margin: 20px 0;
      }

      .tips-item-box {
        display: flex;
        margin-top: 8px;
        font-size: 12px;
        padding-bottom: 10px;
        justify-content: left;

        .tips-item {
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .top2 {
    background-color: #FF4267;
    height: 8px;
    margin: 0 40px;
    border-radius: 0 0 30px 30px;
  }

  .top3 {
    background-color: #5655B9;
    height: 8px;
    margin: 0 55px;
    border-radius: 0 0 30px 30px;
  }

  .apply-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 20px;
    color: #ffffff;
    .van-button {
      height: 45px;
      border-radius: 8px;
      border: none;
      background: @button-color;
      color: #ffffff;
      font-size: 16px;
    }
  }

  .icon-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 16px 30px;
    justify-content: space-between;

    .icon-content {
      background: #ECF5FF;
      display: flex;
      align-items: center;
      font-size: 12px;
      border-radius: 8px;
      margin-bottom: 10px;
      padding: 10px;
      color: #979797;
      justify-content: space-between;
      width: 100%;

      .left-div {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .icon {
        width: 20px;
        margin-right: 5px;
      }

      .icon-title {
        font-size: 14px;
        text-align: center;
      }
    }
    .icon-content.history {
      padding:  10px;
      .icon {
        width: 28px;
        margin-right: 15px;
        margin-left: -5px;
      }
    }
  }

  .policy-div {
    margin-top: 10px;
    font-size: 12px;
    color: #ffffff;
    padding-bottom: 15px;

    .select-div {
      height: 20px;
      width: 20px;
    }

    .read {
      color: #ffffff;
    }

    .policy {
      color: red;
    }
  }

  .policy-content-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .policy-content {
    padding: 10px;
    background-color: #ffffff;
    margin: auto;
    max-height: calc(100% - 120px);
    width: 80%;
    overflow: scroll;
  }

  .reject-box {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      border-radius: 6px;
      font-size: 14px;

      .error-msg {
        color: #535D66;
      }

      /deep/ .van-button {
        height: 35px;
        font-size: 14px;
        background: #FF3D00;
        border-radius: 6px;
        margin-top: 15px;
      }
    }
  }

  .bottom-img {
    margin-top: 20px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

</style>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>